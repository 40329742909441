import { Injectable } from '@angular/core';
import { IContextualContact, CONTEXTUAL_OPERATION_TYPE, CHANNEL_TYPES, IContextualContactChannel } from '../api/AmcApi';
import { BehaviorSubject, Subject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { OFHelper } from './OFHelper';
import { IGlobalConfiguration } from '../model/GlobalConfiguration';
import bind from 'bind-decorator';

@Injectable({
  providedIn: 'root'
})
export class ContextualContactsService {
  public contactsByApp$ = new BehaviorSubject<IPluginContacts>({});
  public sharedContacts$ = new ReplaySubject<IContextualContact[]>(1);
  public selectedApp$ = new ReplaySubject<string>(1);

  private contactsByApp: IPluginContacts = {};
  private globalConfig: IGlobalConfiguration;
  private channelTypeFilter: CHANNEL_TYPES[] = [];

  constructor(private http: HttpClient, private configService: ConfigurationService, private ofHelper: OFHelper) {
    ofHelper.globalConfiguration$.subscribe((conf) => {
      this.globalConfig = conf;

      // Convert channel filters to enum. Remove any invalid ones
      this.channelTypeFilter = conf.LivePresenceChannelTypeFilter.map((type) => CHANNEL_TYPES[type]).filter((type) => type !== undefined);
    });
    ofHelper.onAppRegister$.subscribe(this.onAppRegister);
    ofHelper.onAddContextualContacts$.subscribe(this.onAddContextualContacts);
    ofHelper.onClearContextualContacts$.subscribe(this.clearContextualContacts);
  }

  public updateSharedContacts() {
    this.http
      .get<any[]>(`${this.configService.config.apiUrl}/${this.configService.config.apiVersion}/Api/Presence`, {
        withCredentials: true
      })
      .subscribe((contacts) =>
        this.sharedContacts$.next(
          this.parseContacts(contacts) // parse the contacts to correct type
            .filter(
              (
                contact // Live presence filters
              ) =>
                this.filterByGroupName(contact) &&
                this.filterByPresence(contact) &&
                this.filterByApp(contact) &&
                this.filterByChannelType(contact) &&
                this.filterByChannelPresence(contact)
            )
        )
      );
  }

  private filterByApp(contact: IContextualContact) {
    if (!this.globalConfig || this.globalConfig.LivePresenceFilterOnApp.length === 0) {
      return true;
    }
    // filter out channels based on app
    contact.channels = contact.channels.filter((channel) => this.globalConfig.LivePresenceFilterOnApp.includes(channel.createdByApp));
    return contact.channels.length > 0;
  }

  private filterByChannelPresence(contact: IContextualContact) {
    // filter out channels based on their configured presence
    contact.channels = contact.channels.filter(
      (channel) =>
        channel.validPresences == null || channel.validPresences.length === 0 || contact.presence == null || channel.validPresences.includes(contact.presence)
    );

    return contact.channels.length > 0;
  }

  private filterByPresence(contact: IContextualContact) {
    return (
      !this.globalConfig ||
      this.globalConfig.LivePresenceFilterOnPresence.length === 0 ||
      this.globalConfig.LivePresenceFilterOnPresence.includes(contact.presence)
    );
  }

  private filterByChannelType(contact: IContextualContact) {
    if (this.channelTypeFilter.length === 0) {
      return true;
    }

    // filter out channels
    contact.channels = contact.channels.filter((channel) => this.channelTypeFilter.includes(channel.channelType));

    return contact.channels.length > 0;
  }

  private filterByGroupName(contact: IContextualContact) {
    return (
      !this.globalConfig ||
      this.globalConfig.LivePresenceGroupNameFilter.length === 0 ||
      !contact.groupName ||
      this.ofHelper.globalConfiguration.LivePresenceGroupNameFilter.includes(contact.groupName)
    );
  }

  /**
   * This parses the contacts from the rest api and correctly formats them
   * @param contacts The contacts as returned from the rest api
   */
  private parseContacts(contacts: any[]): IContextualContact[] {
    try {
      return (contacts || []).map((contact) => {
        contact.channels = Object.values<IContextualContactChannel[]>(contact.channels || [])
          .reduce((a, b) => [...a, ...b], [])
          .map((channel) => {
            channel.channelType = parseInt(<any>channel.channelType, 10);
            return channel;
          });
        return contact;
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Called when an app registers for contextual events
   * @param appName Name of app that registered
   */
  @bind
  public onAppRegister(appName: string) {
    // add app to list iff they register
    if (this.contactsByApp[appName] == null) {
      this.contactsByApp[appName] = { contacts: [] };
      this.contactsByApp$.next({ ...this.contactsByApp });
    }
  }

  /**
   * This is called when an app sets new contextual contacts contacts
   * @param appName
   * @param contacts
   */
  @bind
  public onAddContextualContacts([appName, contacts]: [string, IContextualContact[]]) {
    // make sure channels is defined
    for (const contact of contacts) {
      contact.channels = contact.channels || [];
    }

    if (this.contactsByApp[appName] == null) {
      this.contactsByApp[appName] = { contacts: [] };
    }
    this.contactsByApp[appName].contacts = contacts;
    this.contactsByApp$.next({ ...this.contactsByApp });
  }

  /**
   * Called when an app wants to remove all its contextual contacts
   * @param appName
   */
  @bind
  public clearContextualContacts(appName: string) {
    if (this.contactsByApp[appName] != null) {
      this.contactsByApp[appName].contacts = [];
      this.contactsByApp$.next({ ...this.contactsByApp });
    }
  }

  @bind
  public selectApp(appName: string) {
    this.selectedApp$.next(appName);
  }
}

export interface IPluginContacts {
  [pluginId: string]: {
    contacts: IContextualContact[];
    requestedOperation?: CONTEXTUAL_OPERATION_TYPE | undefined;
    requestedOperationMessage?: any;
    requestedOperationChannelType?: CHANNEL_TYPES;
  };
}
